<template>
    <!--用户列表 - 菜单组件-->
    <div class="menuClass">
        <el-button @click="filterUser" style="margin-right: 10px">{{ lang['menu.filter'] }}</el-button>
        <el-dropdown>
            <el-button class="el-dropdown-link">
                Tools
                <el-icon class="el-icon--right">
                    <arrow-down/>
                </el-icon>
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="recoveryUser">{{ lang['menu.recovery'] }}</el-dropdown-item>
                    <el-dropdown-item @click="jumpMoveUser">{{ lang['menu.move'] }}</el-dropdown-item>
<!--                    <el-dropdown-item @click="filterUser">{{ lang['menu.filter'] }}</el-dropdown-item>-->
                    <el-dropdown-item @click="outputData">{{ lang['menu.output'] }}</el-dropdown-item>
                    <el-dropdown-item @click="addUserMoney">{{ lang['menu.addUserMoney'] }}</el-dropdown-item>
                    <el-dropdown-item
                        @click="()=>$emit('FilterNotCutUserEvent')"
                    >
                        {{ lang['menu.FilterNotUsedCutUser'] }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>


        <!--    筛选器-->
        <userListFilter ref="userListFilterRef"
                        :tableData=this.tableData
                        @watch-filter=watchFilterData
        ></userListFilter>
    </div>
</template>

<script>
import lib from '../../../utils/common';
import userListFilter from './userList-filter';
import {toBase64} from "js-base64";

export default {
    name: "userList-menu",
    props: [
        'selectArr',//表格中被选中的用户
        'tableData',//表格全部用户数据
    ],
    emits:[
        'filter',//向userList父组件传递筛选后的用户数据
        'FilterNotCutUserEvent',
    ],
    components: {
        userListFilter
    },
    data() {
        return {
            lang: this.$lang.getLang('user', 'userList-menu'),
        }
    },
    methods: {
        errorMsg(msg) {
            this.$alert(msg, 'Error', {type: 'error'});
        },

        /**
         * 取出被多选的用户指定字段数据
         * @param field 字段名,默认为id
         * @returns {null|*[]}
         * 成功返回数组 失败会弹出错误信息框,并返回null
         */
        getSelectArr(field = 'id'){
            let arr = this.selectArr;
            if (arr.length <= 0) {
                this.errorMsg(this.lang['error.length']);
                return null;
            }
            let resArr = [];//被选中的id
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                resArr.push(temp[field]);
            }
            return resArr;
        },

        //回收用户功能
        recoveryUser() {
            let userArr = this.getSelectArr('username');
            if (!userArr){
                return;
            }
            let maxLength = 20;//最大操作用户数量限制
            if (userArr.length > maxLength){
                //操作的用户超过x个了
                this.errorMsg(this.lang['error.max'] + ' [' + maxLength + ']');
                return;
            }
            console.log('回收用户', userArr);
            let userList = userArr.toString();
            userList = toBase64(userList);
            this.$router.push({name:'user.recovery',params:{userList:userList}})
        },

        //批量充值用户
        addUserMoney(){
            let userArr = this.getSelectArr('username');
            if (!userArr){
                return;
            }
            let maxLength = 1000;//最大操作用户数量限制
            if (userArr.length > maxLength){
                //操作的用户超过x个了
                this.errorMsg(this.lang['error.max'] + ' [' + maxLength + ']');
                return;
            }
            let userList = userArr.toString();
            userList = toBase64(userList);
            this.$router.push({name:'user.userAddMoneyBatch',params:{userList:userList}})
        },

        //迁移用户功能
        jumpMoveUser(){
            let userArr = this.getSelectArr('username');
            if (!userArr){
                return;
            }
            let maxLength = 20;//最大操作用户数量限制
            if (userArr.length > maxLength){
                //操作的用户超过x个了
                this.errorMsg(this.lang['error.max'] + ' [' + maxLength + ']');
                return;
            }
            let userList = userArr.toString();
            userList = toBase64(userList);
            this.$router.push({name:'user.moveUser',params:{userNameList:userList}})
        },

        //导出数据功能
        outputData() {
            this.$confirm("请选中要导出的类型?", '提示', {
                confirmButtonText: '全部用户',
                cancelButtonText: '选中用户',
                type: 'warning'
            }).then(() => {
                this.makeOutPutData(this.tableData, 'allUser.csv');

            }).catch(() => {
                this.makeOutPutData(this.selectArr, 'userList.csv');
            });
        },
        //[导出数据功能]生成并导出数据
        makeOutPutData(arr, fileName) {
            if (arr.length <= 0) {
                this.errorMsg(this.lang['makeOutPutData.length']);
                return;
            }
            this.$message({
                message: this.lang['makeOutPutData.wait'],
                type: 'info',
            });
            let enter = '\r\n';
            let msg = '';
            let version = this.$cookie.getCookie('version');
            let moneyField = version == 1 ? 'money_int' : 'money_time';
            let headerArr = ['id', 'username', 'name', 'status', 'nickname', 'contact', 'login', 'register', moneyField];
            let header = headerArr.toString();
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                let res = [
                    temp['id'],
                    temp['username'],
                    temp['name'],
                    temp['status'],
                    temp['nickname'],
                    temp['contact'],
                    temp['login_time'],
                    temp['reg_time'],
                    temp[moneyField],
                ];
                msg += res.toString() + enter
            }
            let result = header + enter + msg;
            lib.downLoad(result, fileName)
            //延迟提示,否则容易多个弹窗混乱
            setTimeout(() => {
                this.$message({
                    message: this.lang['makeOutPutData.successful'],
                    type: 'success',
                });
            }, 2000)
        },

        //筛选用户功能
        //调用筛选组件[打开筛选器]
        filterUser() {
            this.$refs.userListFilterRef.setShow(true);
        },
        //接收子组件传来的筛选后的用户数据
        watchFilterData(userArray){
            // console.log('watchFilterData',userArray);
            //向顶级父组件传入筛选后的用户数据
            this.$emit('filter',userArray);
            this.$refs.userListFilterRef.setShow(false);
        },
    },

}
</script>

<style scoped>
.menuClass {
    text-align: left;
    margin-bottom: 5px;
    margin-left: 5px;
}

.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}
</style>